<template>
    <div>
        <v-navigation-drawer
                v-model="drawer"
                app
        >
            <v-list dense>
                <v-list-item link to="dashboard">
                    <v-list-item-action>
                        <v-icon>mdi-contact-mail</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="globalapp">
                    <v-list-item-action>
                        <v-icon>mdi-bank</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>College Application</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
                app
                color="indigo"
                dark
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-toolbar-title>Application</v-toolbar-title>
        </v-app-bar>
        <v-content>
            <div class="page-wrapper">
                <router-view></router-view>

            </div>
        </v-content>
        <Footer></Footer>
    </div>
</template>

<script>
    import Footer from "../../components/Footer";
    export default {
        name: "DashboardLayout",
        components: {Footer},
        data: () => ({
            drawer: false,
        }),
    }
</script>

<style scoped>

</style>
