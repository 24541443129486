<template>
  <v-content>
    <div ref="globalappWrap" class="page-wrapper">
      <header>
        <div class="container">
          <img
            class="logo"
            alt="Spring Arbor University Logo"
            src="../../../src/assets/sau_logo_blue.svg"
          />
          <h1 class="text-center sr-only">Spring Arbor University Traditional Application</h1>
        </div>
      </header>
      <router-view v-on="$listeners"></router-view>
      <footer class="footer">
        <svg viewBox="0 0 1440 163">
          <defs>
            <path id="a" d="M0 .891h1440v162H0z" />
            <path id="c" d="M0 .312h1440v86H0z" />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(0 -.891)">
              <path
                d="M1440 695H0V112.067c443.89 50.659 1092.549-8.13 1440-111.176V695z"
                fill="#fed100"
              />
            </g>
            <g transform="translate(0 76.109)">
              <path
                d="M1440 618H0V40.067c328.954 64.53 803.228 68.778 1440-39.755V618z"
                fill="#003a70"
              />
            </g>
          </g>
        </svg>
        <div class="wrap">
          <div class="container">
            <div class="footer__copyright">
              <ul>
                <li>Policies:</li>
                <li>
                  <a
                    tabindex="2"
                    target
                    href="https://www.arbor.edu/policies/title-ix-policy-statement/"
                  >Title IX Policy</a>
                </li>
                <li>
                  <a
                    tabindex="2"
                    target
                    href="https://www.arbor.edu/policies/privacy-policy/"
                  >Privacy Policy</a>
                </li>
                <li>
                  <a
                    tabindex="2"
                    target
                    href="https://www.arbor.edu/policies/student-right-to-know/"
                  >Student's Right to Know</a>
                </li>
                <li>
                  <a
                    tabindex="2"
                    target
                    href="https://www.arbor.edu/policies/complaint-resolutions/"
                  >Complaint Resolution</a>
                </li>
                <li>
                  <a
                    tabindex="2"
                    target
                    href="https://www.arbor.edu/conditions-of-use/"
                  >Conditions of Use</a>
                </li>
                <li>
                  <a tabindex="2" target href="https://www.arbor.edu/emergency/">Emergency</a>
                </li>
              </ul>
              <div class="break"></div>
              <div class="copyright">©{{currentyear}} Spring Arbor University</div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </v-content>
</template>

<script>
export default {
  name: "GlobalAppLayout",
  data() {
    return {
      currentyear: ""
    };
  },
  created() {
    this.currentyear = new Date().getFullYear();
  }
};
</script>

<style lang="scss">
.v-stepper {
  margin-bottom: 450px;
}
.v-stepper__content {
  max-width: 960px;
  margin: auto;
}
header .container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 175px;
  padding: 1em;
}
.footer {
  color: white;
  font-family: "proxima-nova", sans-serif;
  overflow: hidden;
  z-index: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer .wrap {
  background: #003a70;
}

.footer > svg {
  width: 100%;
  display: block;
}

.footer__edge svg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  transform: scale(1);
}

@media (min-width: 1024px) {
  .footer__top {
    display: flex;
    flex-direction: row-reverse;
  }
  .v-stepper {
    margin-bottom: 350px;
  }
}

.footer__contact {
  padding: 44px 22px 0px 22px;
}

@media (min-width: 1024px) {
  .footer__contact {
    padding-left: 0;
    text-align: center;
  }
}

.footer__logo {
  width: 180px;
  display: block;
  height: auto;
  margin: 0 auto;
  margin-bottom: 32px;
}

.footer__copy {
  text-align: center;
  margin-bottom: 32px;
}

@media (min-width: 1024px) {
  .footer__copy {
    margin-bottom: inherit;
  }
}

.footer__copy address {
  display: block;
}

.footer__social-links {
  margin: 24px 0;
}

.footer__social-links ul {
  display: flex;
  justify-content: space-between;
  max-width: 240px;
  margin: 0 auto;
}

.footer__social-links ul li a {
  display: block;
  width: 32px;
}

.footer__social-links ul li svg {
  display: block;
  width: 100%;
}

.footer__menu {
  text-align: center;
}

@media (min-width: 1024px) {
  .footer__menu {
    text-align: left;
    flex-grow: 1;
    padding: 44px 0;
  }
}

.footer__menu ul {
  margin-bottom: 40px;
  padding-left: 0;
}

.footer__menu li {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9em;
  letter-spacing: 0.15em;
}

.footer__menu li:first-child {
  color: #fed100;
  font-size: 1.333em;
  font-weight: 700;
  margin-bottom: 24px;
  text-transform: none;
  letter-spacing: inherit;
}

.footer__menu a {
  transition: color 0.3s;
}

.footer__menu a:hover {
  color: #fed100;
  transition: color 0.3s;
}

.footer__menu form {
  width: 100%;
  max-width: 260px;
  position: relative;
  margin-bottom: 40px;
}

.footer__menu form label {
  display: none;
}

.footer__menu input[type="text"] {
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid #ececec;
  width: 100%;
  font-size: 1em;
  line-height: 40px;
  height: 40px;
  padding: 0 20px;
  border-radius: 4px;
  color: white;
  font-family: "proxima-nova", sans-serif;
}

.footer__menu input[type="text"]::-webkit-input-placeholder {
  color: white;
}

.footer__menu input[type="text"]:-moz-placeholder {
  color: white;
}

.footer__menu input[type="text"]::-moz-placeholder {
  color: white;
}

.footer__menu input[type="text"]:-ms-input-placeholder {
  color: white;
}

.footer__menu input[type="submit"] {
  width: 40px;
  height: 40px;
  -webkit-appearance: none;
  position: absolute;
  right: 5px;
  top: 0;
  border: none;
  background-color: transparent;
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;
}

@media (min-width: 1024px) {
  .footer__menu {
    display: block;
    flex-basis: 66.66%;
  }

  .footer__menu ul {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%;
  }

  .footer__menu ul:nth-child(3) {
    float: right;
    margin-right: 0;
  }

  .footer__menu a.hide-on-mobile {
    display: inline-block;
    float: left;
    margin-left: 20px;
    vertical-align: middle;
    white-space: nowrap;
    line-height: 40px;
  }

  .footer__menu form {
    clear: both;
    width: 260px;
    float: left;
  }
}

.footer__copyright {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2em 0 1em;
  font-size: 0.9em;
}

.footer__copyright img {
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 240px;
}

.footer__copyright ul li {
  margin-bottom: 10px;
  display: block;
  display: block;
}

.footer__copyright ul a {
  font-family: "proxima-nova", sans-serif;
  letter-spacing: 1px;
  text-decoration: underline;
  color: #ffffff;
}

.footer__copyright .copyright {
  padding: 16px;
  width: 100%;
}

.footer__copyright .break {
  flex-basis: 100%;
  height: 0;
}

@media (min-width: 1024px) {
  .footer__copyright {
    flex-direction: row;
    align-items: center;
  }

  .footer__copyright ul {
    display: flex;
    flex-shrink: 0;
    margin: 0;
  }

  .footer__copyright ul li {
    margin-bottom: 0;
  }

  .footer__copyright ul li:after {
    content: "|";
    margin: 0 8px;
  }

  .footer__copyright ul li:first-child {
    margin-right: 8px;
  }

  .footer__copyright ul li:first-child:after {
    display: none;
  }

  .footer__copyright ul li:last-child:after {
    display: none;
  }

  .footer__copyright span {
    border-top: 0;
    width: auto;
    margin-left: auto;
    text-align: left;
  }
}

.footer--landing .footer__top {
  align-items: flex-end;
}

.footer--landing .footer__logo img {
  width: 100%;
  display: block;
}

.footer__menu--landing ul {
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 0;
}

.footer__menu--landing li {
  margin-right: 16px;
}

.footer__menu--landing li:first-child {
  color: inherit;
  font-size: 0.9em;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}
</style>
