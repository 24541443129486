<template>
  <v-app id="login">
    <header>
      <div class="container">
        <img
          class="logo"
          alt="Spring Arbor University Logo"
          src="../../../src/assets/sau_logo_blue.svg"
        />
        <h1 class="text-center sr-only">
          Spring Arbor University Traditional Application
        </h1>
      </div>
    </header>
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12 login-box">
              <v-toolbar color="primary" dark flat height="auto">
                <v-toolbar-title v-if="loginType === 'new'"
                  >Start New Application</v-toolbar-title
                >
                <v-toolbar-title v-if="loginType === 'return'"
                  >Login to continue your application</v-toolbar-title
                >
                <v-toolbar-title v-if="loginType === 'forgot'"
                  >Request a new login link</v-toolbar-title
                >
                <v-toolbar-title v-if="loginType === 'reset'"
                  >Would you like to continue with your temporary login link or
                  change your password?</v-toolbar-title
                >
                <v-toolbar-title v-if="loginType === 'updatepass'"
                  >Create a new password.</v-toolbar-title
                >

                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row v-if="loginType === 'reset'">
                    <v-col cols="12">
                      <v-btn
                        class="mr-3"
                        color="primary"
                        type="button"
                        @click.prevent="loginType = 'updatepass'"
                        >Change My Password</v-btn
                      >
                      <v-btn
                        class="mr-3"
                        color="primary"
                        type="button"
                        @click.prevent="goToForm()"
                        >Yes, Continue</v-btn
                      >
                    </v-col>
                  </v-row>

                  <v-row v-if="loginType === 'new'">
                    <v-col cols="12" md="6">
                      <v-text-field
                        validate-on-blur
                        v-model="model.firstName"
                        :rules="nameRules"
                        clearable
                        label="First Name"
                        name="firstName"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        validate-on-blur
                        v-model="model.lastName"
                        :rules="nameRules"
                        clearable
                        label="Last Name"
                        name="lastName"
                        type="text"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="loginType === 'new' || loginType === 'return'">
                    <v-col cols="12">
                      <v-text-field
                        validate-on-blur
                        v-model="model.email"
                        :rules="emailRules"
                        clearable
                        label="Email"
                        name="email"
                        type="text"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="loginType === 'new'">
                    <v-col cols="12">
                      <v-text-field
                        validate-on-blur
                        v-model="model.cell"
                        :rules="mobileRules"
                        clearable
                        label="Mobile Phone"
                        name="mobile"
                        type="text"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Track and Campus Questions -->
                  <!-- <v-row v-if="loginType === 'new'">
                    <v-col col="12" md="12">
                      <v-radio-group
                        :rules="required"
                        label="Desired Degree Level"
                        :mandatory="false"
                        v-model="degLvl"
                      >
                        <v-radio v-if="campusTracks[1]" label="Non-Degree Grad" value="Non-Degree Grad"></v-radio>
                        <v-radio v-if="campusTracks[0]" label="Certificate" value="Certificate"></v-radio>
                        <v-radio v-if="campusTracks[3]" label="Bachelor's" value="Bachelors"></v-radio>
                        <v-radio v-if="campusTracks[2]" label="Master's" value="Graduate"></v-radio>
                        <v-radio v-if="campusTracks[4]" label="Doctorate" value="Doctorate"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row> -->

                  <v-row v-if="loginType === 'new'">
                    <v-col col="12" md="12">
                      <v-autocomplete
                        :rules="checkTrak"
                        v-model="program"
                        :items="filteredTraks"
                        item-text="txt"
                        item-value="txt"
                        return-object
                        label="Select program"
                        target="#dropdown-example"
                        editable
                        lazy-validation
                      ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="loginType === 'new'"
                    :class="{
                      hide:
                        siteList.length < 2 ||
                        (!siteList.includes('Online') &&
                          !siteList.includes('Virtual Classroom')),
                    }"
                  >
                    <v-col col="12" md="12">
                      <v-radio-group
                        @change="classTypeChange()"
                        :rules="required"
                        label="What classroom type do you prefer?"
                        v-model="classTypeValue"
                      >
                        <v-radio
                          :class="{ hide: !siteList.includes('Online') }"
                          label="Online"
                          value="Online"
                        ></v-radio>
                        <v-radio
                          :class="{
                            hide: !siteList.includes('Virtual Classroom'),
                          }"
                          label="Virtual Classroom"
                          value="Virtual Classroom"
                        ></v-radio>
                        <v-radio
                          label="Face-To-Face"
                          value="Face-To-Face"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="loginType === 'new'"
                    :class="{
                      hide:
                        siteList.length > 1 ||
                        !siteList.includes('Virtual Classroom') ||
                        (siteList.length > 1 &&
                          !siteList.includes('Virtual Classroom')),
                    }"
                  >
                    <v-col col="12" md="12">
                      <p>This program is offered as a virtual classroom only</p>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="loginType === 'new'"
                    :class="{
                      hide:
                        siteList.length > 1 ||
                        !siteList.includes('Online') ||
                        (siteList.length > 1 && !siteList.includes('Online')),
                    }"
                  >
                    <v-col col="12" md="12">
                      <p>This program is offered online only</p>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="program.trak && loginType === 'new'"
                    :class="{
                      hide:
                        campus === 'Online' || campus === 'Virtual Classroom',
                    }"
                  >
                    <v-col col="12" md="12">
                      <v-autocomplete
                        v-if="classTypeValue === 'Face-To-Face'"
                        :rules="required"
                        v-model="campus"
                        :items="program.campuses"
                        item-text="campus"
                        label="Select face-to-face location"
                        target="#dropdown-example"
                        editable
                        clearable
                      >
                        <template v-slot:item="{ item, attrs, on }">
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                            :class="{
                              hide:
                                item.campus === 'Online' ||
                                item.campus === 'Virtual Classroom',
                            }"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                :id="attrs['aria-labelledby']"
                                v-text="item.campus"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- END Track and Campus Questions -->

                  <v-row
                    v-if="loginType === 'new' || loginType === 'updatepass'"
                  >
                    <v-col cols="12">
                      <v-text-field
                        validate-on-blur
                        v-model="model.createPassword"
                        :rules="passwordRules"
                        clearable
                        label="Create Password"
                        name="createPassword"
                        type="password"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="loginType === 'new' || loginType === 'updatepass'"
                  >
                    <v-col cols="12">
                      <v-text-field
                        @keyup.enter.native="validate() && login()"
                        validate-on-blur
                        v-model="model.verifyPassword"
                        :rules="verifyPasswordRules"
                        clearable
                        label="Verify Password"
                        name="verifyPassword"
                        type="password"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="loginType === 'return'">
                    <v-col cols="12">
                      <v-text-field
                        @keyup.enter.native="validate() && login()"
                        validate-on-blur
                        v-model="model.password"
                        clearable
                        label="Password"
                        name="password"
                        type="password"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="loginType === 'new'">
                    <v-col cols="12">
                      <v-checkbox
                        @keyup.enter.native="validate() && login()"
                        v-model="model.text"
                        label="You may text me important updates and reminders."
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row v-if="loginType === 'forgot'">
                    <v-col cols="12">
                      <v-radio-group
                        v-model="verifyType"
                        label="How would you like us to verify your identity?"
                      >
                        <v-radio label="Email" value="email"></v-radio>
                        <v-radio label="Mobile Number" value="mobile"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="loginType === 'forgot' && verifyType === 'email'"
                  >
                    <v-col cols="12">
                      <v-text-field
                        @keyup.enter.native="validate() && login()"
                        validate-on-blur
                        v-model="model.verifyEmail"
                        :rules="emailRules"
                        clearable
                        label="Verify by email"
                        name="verifyEmail"
                        type="text"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="loginType === 'forgot' && verifyType === 'mobile'"
                  >
                    <v-col cols="12">
                      <v-text-field
                        @keyup.enter.native="validate() && login()"
                        validate-on-blur
                        v-model="model.verifyMobile"
                        :rules="mobileRules"
                        clearable
                        label="Verify by mobile number"
                        name="verifyMobile"
                        type="text"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="pa-4" v-if="loginType !== 'reset'">
                <v-row>
                  <v-col col="12" md="12">
                    <v-btn
                      class="mr-3"
                      color="primary"
                      @click="validate() && login()"
                      v-if="loginType === 'new' || loginType === 'return'"
                      ><span v-if="loginType === 'return'">Login</span
                      ><span v-if="loginType === 'new'">Start</span></v-btn
                    >
                    <v-btn
                      class="mr-3"
                      color="primary"
                      @click="validate() && login()"
                      v-if="
                        loginType === 'forgot' || loginType === 'updatepass'
                      "
                      >Submit</v-btn
                    >
                  </v-col>
                  <v-col col="12" md="12">
                    <v-btn
                      class="ma-0"
                      x-small
                      text
                      v-if="loginType === 'new' || loginType === 'forgot'"
                      @click="loginType = 'return'"
                    >
                      <v-icon small class="mr-1">mdi-account-check</v-icon>I
                      already have an account
                    </v-btn>
                    <v-btn
                      class="ma-0"
                      x-small
                      text
                      v-if="loginType === 'return'"
                      @click="loginType = 'new'"
                    >
                      <v-icon small class="mr-1">mdi-pencil</v-icon>I'm a new
                      applicant
                    </v-btn>
                    <v-btn
                      class="ma-0"
                      x-small
                      text
                      v-if="loginType === 'return'"
                      @click="loginType = 'forgot'"
                    >
                      <v-icon small class="mr-1">mdi-account-question</v-icon>I
                      don't know my password
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
    <footer class="footer">
      <svg viewBox="0 0 1440 163">
        <defs>
          <path id="a" d="M0 .891h1440v162H0z" />
          <path id="c" d="M0 .312h1440v86H0z" />
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(0 -.891)">
            <path
              d="M1440 695H0V112.067c443.89 50.659 1092.549-8.13 1440-111.176V695z"
              fill="#fed100"
            />
          </g>
          <g transform="translate(0 76.109)">
            <path
              d="M1440 618H0V40.067c328.954 64.53 803.228 68.778 1440-39.755V618z"
              fill="#003a70"
            />
          </g>
        </g>
      </svg>
      <div class="wrap">
        <div class="container">
          <div class="footer__copyright">
            <ul>
              <li>Policies:</li>
              <li>
                <a
                  tabindex="2"
                  target
                  href="https://www.arbor.edu/policies/title-ix-policy-statement/"
                  >Title IX Policy</a
                >
              </li>
              <li>
                <a
                  tabindex="2"
                  target
                  href="https://www.arbor.edu/policies/privacy-policy/"
                  >Privacy Policy</a
                >
              </li>
              <li>
                <a
                  tabindex="2"
                  target
                  href="https://www.arbor.edu/policies/student-right-to-know/"
                  >Student's Right to Know</a
                >
              </li>
              <li>
                <a
                  tabindex="2"
                  target
                  href="https://www.arbor.edu/policies/complaint-resolutions/"
                  >Complaint Resolution</a
                >
              </li>
              <li>
                <a
                  tabindex="2"
                  target
                  href="https://www.arbor.edu/conditions-of-use/"
                  >Conditions of Use</a
                >
              </li>
              <li>
                <a tabindex="2" target href="https://www.arbor.edu/emergency/"
                  >Emergency</a
                >
              </li>
            </ul>
            <div class="break"></div>
            <div class="copyright">
              ©{{ currentyear }} Spring Arbor University
            </div>
          </div>
        </div>
      </div>
    </footer>
  </v-app>
</template>

<script>
//import DemoSwitch from "./DemoSwitch";
import Vue from "vue";
import _ from "lodash";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState, mapMutations } =
  createNamespacedHelpers("globalApplication");
import { normalizePhone } from "@/utils";
export default {
  components: {
    //DemoSwitch
  },
  data: () => ({
    //Eloqua Items
    strURL: "",
    campaign: "",
    source: "",
    medium: "",
    content: "",
    keyword: "",
    elqCustomerGUID: "",

    loading: false,
    loginError: false,
    loginType: "new",
    verifyType: "email",
    loginButton: "Login",
    ipaddress: "",

    //Program Fields
    programSet: false,
    classTypeValue: "",
    siteList: [],
    //end

    model: {
      firstName: "",
      lastName: "",
      email: "",
      cell: "",
      text: false,
      createPassword: "",
      verifyPassword: "",
      password: "",
      verifyEmail: "",
      verifyMobile: "",
      track: "",
      desiredDegreeLevel: "",
      campus: "",
      requiresBa: false,
      appGuid: "",
      graduateFromSAU: "",
    },
    valid: false,
    required: [(v) => !!v || "This field is required."],
    nameRules: [
      (v) => !!v || "This field is required",
      (v) =>
        (v && v.length <= 24) || "This field must be less than 24 characters.",
    ],
    emailRules: [
      (v) => !!v || "This field is required",
      (v) => /.+@.+\..+/.test(v) || "A valid email address is required",
    ],
    mobileRules: [
      (v) => !!v || "This field is required",
      (v) =>
        /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(v) ||
        "A valid 10 digit phone number is required",
    ],
    passwordRules: [
      (v) => !!v || "This field is required",
      (v) =>
        (v && v.length >= 8) || "This field must be more than 8 characters",
    ],
  }),
  computed: {
    ...mapState(["emailKey", "degLvlQueryArgument", "campusTracks"]),
    //Rules
    verifyPasswordRules() {
      let self = this;
      return [
        (v) => !!v || "This field is required",
        () =>
          self.model.verifyPassword === self.model.createPassword ||
          "This field must match password",
      ];
    },
    checkTrak() {
      let self = this;
      return [
        () => self.model.track != "" || "Please select a program of interest.",
      ];
    },

    degLvl: {
      get() {
        return this.model.desiredDegreeLevel;
      },
      set(val) {
        this.model.desiredDegreeLevel = val;
      },
    },
    graduateFromSAU: {
      get() {
        return this.model.graduateFromSAU;
      },
      set(val) {
        this.model.graduateFromSAU = val;
      },
    },
    filteredTraks() {
      // the server call might not have returned yet
      if (!this.campusTracks || this.campusTracks.length === 0) {
        return [];
      }
      /* if (this.degLvl === "Graduate") {
        return this.campusTracks[2].traks;
      } else if (this.degLvl === "Non-Degree Grad") {
        return this.campusTracks[1].traks;
      } else if (this.degLvl === "Bachelors") {
        return this.campusTracks[3].traks;
      } else if (this.degLvl === "Doctorate") {
        return this.campusTracks[4].traks;
      } else if (this.degLvl === "Certificate") {
        return this.campusTracks[0].traks;
      } else { */
      let traks = [];
      this.campusTracks.forEach(function (item) {
        item.traks.forEach(function (item) {
          traks.push(item);
        });
      });
      return traks.reverse();
      /* } */
    },
    program: {
      get() {
        let matchingTrak = _.filter(this.filteredTraks, {
          trak: this.model.track,
          nonDegree: (this.model.graduateFromSAU === "N") ? true : false
        });
        matchingTrak = matchingTrak[0]
        if (matchingTrak) {
          return matchingTrak;
        } else {
          return { trak: "", txt: "", campuses: [], ba: "", nonDegree: "" };
        }
      },
      set(val) {
        this.model.graduateFromSAU = (val.nonDegree === true) ? "N" : "Y"
        this.model.track = val.trak;
      },
    },
    campus: {
      get() {
        if (this.program && this.program.campuses) {
          let matchingCampus = _.find(this.program.campuses, {
            campus: this.model.campus,
          });
          if (matchingCampus) {
            return matchingCampus.campus;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      set(val) {
        this.model.campus = val;
      },
    },
  },
  watch: {
    degLvl() {
      //this.program = { trak: "", txt: "", campuses: [], ba: "" };
    },
    program(newProgram, oldProgram) {
      var firstload = true;
      let self = this;
      //Find Degree Level From Program
      for (let i = 0; i < this.campusTracks.length; i++) {
        let thisProg = this.campusTracks[i];
        for (let i = 0; i < thisProg.traks.length; i++) {
          if (thisProg.traks[i].trak === newProgram.trak) {
            if (thisProg.prog === "GRAD") {
              this.model.desiredDegreeLevel = "Graduate";
            } else if (thisProg.prog === "UNDG") {
              this.model.desiredDegreeLevel = "Bachelors";
            } else if (thisProg.prog === "CERT") {
              this.model.desiredDegreeLevel = "Certificate";
            } else if (thisProg.prog === "GND") {
              this.model.desiredDegreeLevel = "Non-Degree Grad";
            }
          }
        }
      }

      if (this.$router.currentRoute.query.program && firstload === true) {
        this.program = _.find(this.filteredTraks, {
          trak: this.$router.currentRoute.query.program,
        });
        firstload = false;
      }
      if (this.programSet) {
        this.classTypeValue = "";
        if (oldProgram && oldProgram.trak && oldProgram.trak !== "") {
          // reset the campus back to blank if the program is changed, but not on the initial load
          this.campus = "";
        }
      }
      self.siteList = [];
      self.program.campuses.map(function (val) {
        self.siteList.push(val.campus);
      });

      if (self.siteList.length === 1) {
        if (
          self.siteList[0] === "Online" ||
          self.siteList[0] === "Virtual Classroom"
        ) {
          self.campus = self.siteList[0];
        }
      }

      if (this.campus === "Virtual Classroom") {
        this.classTypeValue = "Virtual Classroom";
      } else if (this.campus === "Online") {
        this.classTypeValue = "Online";
      } else if (this.campus) {
        this.classTypeValue = "Face-To-Face";
      } else {
        // if the site list contains neither Online nor Virtual Classroom (MSW program on May 18 2020), set the class type to Face-To-Face
        if (
          !self.siteList.includes("Online") &&
          !self.siteList.includes("Virtual Classroom")
        ) {
          this.classTypeValue = "Face-To-Face";
        } else {
          this.classTypeValue = "";
        }
      }

      //this.model.graduateFromSAU = (this.program.nonDegree === true) ? "N" : "Y"

      this.$refs.form.resetValidation();
      this.programSet = true;
    },
  },
  methods: {
    WaitUntilCustomerGUIDIsRetrieved() {
      if (typeof window.GetElqCustomerGUID === "function") {
        this.elqCustomerGUID = window.GetElqCustomerGUID();
        return;
      }
      setTimeout(this.WaitUntilCustomerGUIDIsRetrieved, 500);
      return;
    },
    classTypeChange() {
      if (this.classTypeValue === "Virtual Classroom") {
        this.campus = "Virtual Classroom";
      } else if (this.classTypeValue === "Online") {
        this.campus = "Online";
      } else if (this.classTypeValue === "Face-To-Face") {
        this.campus = "";
      } else {
        this.campus = "";
      }
      this.$refs.form.resetValidation();
    },
    getURLParam(param) {
      if (param !== "undefined") {
        return param;
      } else {
        return "";
      }
    },
    validate() {
      let formIsValid = this.$refs.form.validate();
      if (formIsValid) {
        this.snackbar = true;
      }
      return formIsValid;
    },
    onSelect(e) {
      this.model.dusername = e.userName;
      this.model.password = e.pw;
    },
    requestLogin(emailAddress, password) {
      this.loading = true;
      this.$store
        .dispatch("session/login", {
          emailAddress: emailAddress,
          password: password,
        })
        .then((response) => {
          // save token
          this.loginError = false;
          if (response.success) {
            //console.log("login component ok", response);
            this.loading = false;
            this.$router.push({ path: "/" });
          } else {
            this.loading = false;
            // if we're calling this just to attempt to resume the already-existing user session,
            //   a "failure" is no problem
            if (emailAddress !== "*") {
              this.loginError = true;
              //console.error("login error ");
            }
          }
        })
        .catch((/*error*/) => {
          this.loading = false;
          this.loginError = true;
          //console.error("error with the session login HTTP call", error);
        });
    },
    goToForm() {
      this.$router.push("/globalapp");
    },
    login() {
      if (this.loginError) {
        this.$emit("modalMessage", {
          show: true,
          header: "Login Error",
          message: "Please correct the errors in your form.",
          color: "error",
        });
        //alert("Please correct the errors in your form.");
      } else {
        if (this.loginType === "return") {
          this.loginWithPassword({
            emailAddress: this.model.email,
            password: this.model.password,
          })
            .then((dat) => {
              if (dat.length === 0) {
                this.$emit("modalMessage", {
                  show: true,
                  header: "Login Error",
                  message:
                    "We aren't finding an application for this email address or your password is incorrect.",
                  color: "error",
                });
                //alert("We aren't finding an application for this email address or your password is incorrect.");
              } else {
                let foundApplication = dat[0];
                if (foundApplication.isDone === "Yes") {
                  this.$emit("modalMessage", {
                    show: true,
                    header: "Login Error",
                    message:
                      "You cannot change an application after submitting it.",
                    color: "error",
                  });
                  //alert("You cannot change an application after submitting it.");
                } else {
                  if (foundApplication.app_id === "SENTLINK") {
                    this.$emit("modalMessage", {
                      show: true,
                      header: "Login Error",
                      message:
                        "We see you saved an application without creating a password. A link has been sent to your email to login. The link will expire after 20 minutes.",
                      color: "error",
                    });
                    //alert("We see you saved an application without creating a password. A link has been sent to your email to login. The link will expire after 20 minutes.");
                  } else {
                    //                                      myself.sessionExpire = false
                    Vue.rollbar.configure({
                      payload: {
                        person: {
                          id: this.model.email,
                        },
                      },
                    });
                    this.$router.push("/globalapp");
                  }
                }
              }
            })
            .catch((err) => {
              Vue.rollbar.error(err);
              this.$emit("modalMessage", {
                show: true,
                header: "Login Error",
                message:
                  "Oops, sorry! There was an error loading your application data. Please login again.",
                color: "error",
              });
              //alert("Oops, sorry! There was an error loading your application data. Please login again.");
            });
        } else if (this.loginType === "new") {
          this.startApplication({
            email: this.model.email,
            firstName: this.model.firstName,
            cell: normalizePhone(this.model.cell),
            textOptIn: this.model.text ? "Y" : "",
            newPassword: this.model.verifyPassword,
            lastName: this.model.lastName,
            track: this.model.track,
            desiredDegreeLevel: this.model.desiredDegreeLevel,
            campus: this.model.campus,
            appGuid: this.model.appGuid,
            graduateFromSAU: this.model.graduateFromSAU,
            converted_campaign: this.campaign,
            converted_source: this.source,
            converted_medium: this.medium,
            converted_content: this.content,
            converted_keyword: this.keyword,
            elqCustomerGUID: this.elqCustomerGUID,
          })
            .then(() => {
              Vue.rollbar.configure({
                payload: {
                  person: {
                    id: this.model.email,
                  },
                },
              });
              this.$router.push("/globalapp");
            })
            .catch((err) => {
              if (err.response && err.response.status === 409) {
                this.$emit("modalMessage", {
                  show: true,
                  header: "Login Error",
                  message:
                    "There is already an application started with your information.",
                  color: "error",
                });
                //alert("There is already an application started with your information.");
              } else {
                Vue.rollbar.error(err);
                this.$emit("modalMessage", {
                  show: true,
                  header: "Login Error",
                  message:
                    "There was an error creating your application! The information you provided was not saved.",
                  color: "error",
                });
                //alert("There was an error creating your application! The information you provided was not saved.");
              }
            });
        } else if (this.loginType === "forgot") {
          this.model.verifyMobile = this.model.verifyMobile.replace(
            /[^\w\s]/gi,
            ""
          );
          let linkWarning =
            "The link that has been sent to you can only be used once. The link will expire after 20 minutes.";
          this.forgotPassword({
            emailAddress: this.model.verifyEmail,
            cell: this.model.verifyMobile,
          })
            .then(() => {
              this.$emit("modalMessage", {
                show: true,
                header: "Link Sent",
                message: linkWarning,
                color: "error",
              });
              //alert(linkWarning);
            })
            .catch((err) => {
              let errorDescr =
                "Our apologies, no application was found with entered information or there was an error logging in. Please try again.";
              if (
                err.response &&
                err.response.data &&
                err.response.data.hasOwnProperty("eventCode")
              ) {
                let eventCode = err.response.data.eventCode;
                if (eventCode === "EMAILINSTEAD") {
                  errorDescr =
                    "There was a problem, so an email was sent to you instead. This was the problem: " +
                    err.response.data.messageError +
                    " " +
                    linkWarning;
                }
              }
              Vue.rollbar.error(err);
              this.$emit("modalMessage", {
                show: true,
                header: "Login Error",
                message: errorDescr,
                color: "error",
              });
              //alert(errorDescr);
            });
        } else if (this.loginType === "updatepass") {
          this.saveNewPassword({ newPassword: this.model.verifyPassword })
            .then(() => {
              this.$emit("modalMessage", {
                show: true,
                header: "Login Message",
                message: "Your password has been changed.",
                color: "primary",
              });
              //alert("Your password has been changed.");
              Vue.rollbar.configure({
                payload: {
                  person: {
                    id: this.emailKey,
                  },
                },
              });
              this.$router.push("/globalapp");
            })
            .catch((err) => {
              Vue.rollbar.error(err);
              this.$emit("modalMessage", {
                show: true,
                header: "Login Error",
                message: "There was a problem changing your password.",
                color: "Error",
              });
              //alert("There was a problem changing your password.");
              this.$router.push("/globalapp");
            });
        }
      }
    },
    sessionResume() {
      this.loginError = false;
      this.requestLogin("*", "*");
    },
    ...mapMutations(["updateApplicationDataProps", "setDegLvlQueryArgument"]),
    ...mapActions([
      "loginWithPassword",
      "startApplication",
      "forgotPassword",
      "loginWithOTP",
      "saveNewPassword",
      "loadTrackInfo",
      "lookupIP"
    ]),
  },
  beforeCreate(){
    //Lookup ip and send to ipstack service then redirect international devices
    let thisApp = this,
    xhrLookupIP = new XMLHttpRequest();
    xhrLookupIP.open("GET", "https://api.ipify.org?format=json", true);
    xhrLookupIP.onload = function () {
      if (xhrLookupIP.readyState === 4) {
        if (xhrLookupIP.status === 200) {
          var response = JSON.parse(xhrLookupIP.responseText);
          this.ipaddress = response
          //console.log(this.ipaddress.ip)
          thisApp.lookupIP({ip: this.ipaddress.ip}).then((ipdata) => {
            //console.log(ipdata)
            if("country_code" in ipdata && ipdata.country_code != "US"){
              window.location.replace("https://www.arbor.edu/admissions/graduate/international-students/")
            } else{
                if(ipdata.error){
                  Vue.rollbar.error(ipdata);
                }
            }
          });
        } else {
          //console.error("could not reach ipify.org");
          Vue.rollbar.error(xhrLookupIP.responseText);
        }
      }
    };
    xhrLookupIP.onerror = function () {
      Vue.rollbar.error(xhrLookupIP.statusText);
    };
    xhrLookupIP.send(null);
  },
  created() {
    this.currentyear = new Date().getFullYear();

  },
  mounted() {

    //Assign UUID
    const { v4: uuidv4 } = require("uuid");
    this.model.appGuid = uuidv4();
    //Set fields from param
    if (this.$router.currentRoute.query.degree === "doc") {
      this.model.desiredDegreeLevel = "Doctorate";
    } else if (this.$router.currentRoute.query.degree === "grad") {
      this.model.desiredDegreeLevel = "Graduate";
    } else if (this.$router.currentRoute.query.degree === "undg") {
      this.model.desiredDegreeLevel = "Bachelors";
    } else if (this.$router.currentRoute.query.degree === "cert") {
      this.model.desiredDegreeLevel = "Certificate";
    } else if (this.$router.currentRoute.query.degree === "gnd") {
      this.model.desiredDegreeLevel = "Non-Degree Grad";
    }

    this.model.firstName = this.$router.currentRoute.query.firstname;
    this.model.lastName = this.$router.currentRoute.query.lastname;
    this.model.cell = this.$router.currentRoute.query.phone;
    this.model.email = this.$router.currentRoute.query.email;

    //Eloqua Tracking
    this.WaitUntilCustomerGUIDIsRetrieved();

    //UTM Capture for Eloqua
    this.campaign = this.getURLParam(this.$route.query.utm_campaign);
    this.source = this.getURLParam(this.$route.query.utm_source);
    this.medium = this.getURLParam(this.$route.query.utm_medium);
    this.content = this.getURLParam(this.$route.query.utm_content);

    //Google(keyword) or Bing(utm_)
    if (this.$route.query.keyword !== undefined) {
      this.keyword = this.$route.query.keyword;
    } else if (this.$route.query.utm_term !== undefined) {
      this.keyword = this.$route.query.utm_term;
    } else {
      this.keyword = "";
    }

    // example this.lookupIP({ip: "104.199.125.52"}).then((ipdata) => { console.log(ipdata) });

    //Login
    this.loginError = false;
    let queryUrlPart = window.location.search;
    if (queryUrlPart !== null && queryUrlPart.length > 3) {
      let oneTimeCode = "";
      let queryNoQuestionMark = queryUrlPart.substring(1);
      let splitQuery = queryNoQuestionMark.split("&");
      splitQuery.forEach((queryPair) => {
        let pairParts = queryPair.split("=");
        if (pairParts[0] === "p") {
          oneTimeCode = pairParts[1];
        }
        if (pairParts[0] === "degLvl") {
          this.setDegLvlQueryArgument(pairParts[1]);
        }
      });
      if (oneTimeCode !== "") {
        // this sets the Authorization header in axios defaults to prepare for secured server calls
        this.$api.authToken
          .setupAuthToken()
          .then(() => {
            return this.loginWithOTP({ oneTimePassword: oneTimeCode });
          })
          .then(() => {
            this.loginType = "updatepass";
          })
          .catch(() => {
            this.$emit("modalMessage", {
              show: true,
              header: "Login Error",
              message:
                "The link you followed here is either expired or invalid. Please log in with your email and password or request a new login link.",
              color: "error",
            });
            //alert("The link you followed here is either expired or invalid. Please log in with your email and password or request a new login link.");
          });
      }
    }
  },
};
</script>
<style scoped lang="css">
#login {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}

header .container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 175px;
  padding: 1em;
}
.login-box {
  position: relative;
  z-index: 2;
  margin-top: 30px;
  margin-bottom: 450px;
}
.footer {
  color: white;
  font-family: "proxima-nova", sans-serif;
  overflow: hidden;
  z-index: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer .wrap {
  background: #003a70;
}

.footer > svg {
  width: 100%;
  display: block;
}

.footer__edge svg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  transform: scale(1);
}

@media (min-width: 1024px) {
  .login-box {
    margin-bottom: 350px;
  }
  .footer__top {
    display: flex;
    flex-direction: row-reverse;
  }
}

.footer__contact {
  padding: 44px 22px 0px 22px;
}

@media (min-width: 1024px) {
  .footer__contact {
    padding-left: 0;
    text-align: center;
  }
}

.footer__logo {
  width: 180px;
  display: block;
  height: auto;
  margin: 0 auto;
  margin-bottom: 32px;
}

.footer__copy {
  text-align: center;
  margin-bottom: 32px;
}

@media (min-width: 1024px) {
  .footer__copy {
    margin-bottom: inherit;
  }
}

.footer__copy address {
  display: block;
}

.footer__social-links {
  margin: 24px 0;
}

.footer__social-links ul {
  display: flex;
  justify-content: space-between;
  max-width: 240px;
  margin: 0 auto;
}

.footer__social-links ul li a {
  display: block;
  width: 32px;
}

.footer__social-links ul li svg {
  display: block;
  width: 100%;
}

.footer__menu {
  text-align: center;
}

@media (min-width: 1024px) {
  .footer__menu {
    text-align: left;
    flex-grow: 1;
    padding: 44px 0;
  }
}

.footer__menu ul {
  margin-bottom: 40px;
  padding-left: 0;
}

.footer__menu li {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9em;
  letter-spacing: 0.15em;
}

.footer__menu li:first-child {
  color: #fed100;
  font-size: 1.333em;
  font-weight: 700;
  margin-bottom: 24px;
  text-transform: none;
  letter-spacing: inherit;
}

.footer__menu a {
  transition: color 0.3s;
}

.footer__menu a:hover {
  color: #fed100;
  transition: color 0.3s;
}

.footer__menu form {
  width: 100%;
  max-width: 260px;
  position: relative;
  margin-bottom: 40px;
}

.footer__menu form label {
  display: none;
}

.footer__menu input[type="text"] {
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid #ececec;
  width: 100%;
  font-size: 1em;
  line-height: 40px;
  height: 40px;
  padding: 0 20px;
  border-radius: 4px;
  color: white;
  font-family: "proxima-nova", sans-serif;
}

.footer__menu input[type="text"]::-webkit-input-placeholder {
  color: white;
}

.footer__menu input[type="text"]:-moz-placeholder {
  color: white;
}

.footer__menu input[type="text"]::-moz-placeholder {
  color: white;
}

.footer__menu input[type="text"]:-ms-input-placeholder {
  color: white;
}

.footer__menu input[type="submit"] {
  width: 40px;
  height: 40px;
  -webkit-appearance: none;
  position: absolute;
  right: 5px;
  top: 0;
  border: none;
  background-color: transparent;
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;
}

@media (min-width: 1024px) {
  .footer__menu {
    display: block;
    flex-basis: 66.66%;
  }

  .footer__menu ul {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%;
  }

  .footer__menu ul:nth-child(3) {
    float: right;
    margin-right: 0;
  }

  .footer__menu a.hide-on-mobile {
    display: inline-block;
    float: left;
    margin-left: 20px;
    vertical-align: middle;
    white-space: nowrap;
    line-height: 40px;
  }

  .footer__menu form {
    clear: both;
    width: 260px;
    float: left;
  }
}

.footer__copyright {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2em 0 1em;
  font-size: 0.9em;
}

.footer__copyright img {
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 240px;
}

.footer__copyright ul li {
  margin-bottom: 10px;
  display: block;
  display: block;
}

.footer__copyright ul a {
  font-family: "proxima-nova", sans-serif;
  letter-spacing: 1px;
  text-decoration: underline;
  color: #ffffff;
}

.footer__copyright .copyright {
  padding: 16px;
  width: 100%;
}

.footer__copyright .break {
  flex-basis: 100%;
  height: 0;
}

@media (min-width: 1024px) {
  .footer__copyright {
    flex-direction: row;
    align-items: center;
  }

  .footer__copyright ul {
    display: flex;
    flex-shrink: 0;
    margin: 0;
  }

  .footer__copyright ul li {
    margin-bottom: 0;
  }

  .footer__copyright ul li:after {
    content: "|";
    margin: 0 8px;
  }

  .footer__copyright ul li:first-child {
    margin-right: 8px;
  }

  .footer__copyright ul li:first-child:after {
    display: none;
  }

  .footer__copyright ul li:last-child:after {
    display: none;
  }

  .footer__copyright span {
    border-top: 0;
    width: auto;
    margin-left: auto;
    text-align: left;
  }
}

.footer--landing .footer__top {
  align-items: flex-end;
}

.footer--landing .footer__logo img {
  width: 100%;
  display: block;
}

.footer__menu--landing ul {
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 0;
}

.footer__menu--landing li {
  margin-right: 16px;
}

.footer__menu--landing li:first-child {
  color: inherit;
  font-size: 0.9em;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}
</style>
