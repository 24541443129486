<template>
    <v-footer height="auto" class="white pa-3 app--footer">
        <span class="caption">SAU IT &copy;{{ new Date().getFullYear() }}</span>
        <v-spacer></v-spacer>
        <span class="caption mr-1"><b>John 1:12 ESV &nbsp;</b>In the beginning was the Word, and the Word was with God, and the Word was God. He was in the Beginning with God. </span>
    </v-footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>
